// reducers/certificateSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../../helpers/toast";
import axios from "axios";
import _ from "lodash";
import qs from "qs";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL, // Replace this with your API's base URL

  // Other default configurations (headers, timeout, etc.) can be set here if needed
});
API.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    showError(error.response?.data?.error ?? error.message);
    return Promise.reject(error);
  }
);

const initialState = {
  data: [],
  loading: false,
  error: null,
  data: [],
};

export const fetchCertificates = createAsyncThunk(
  "fetchCertificates",
  async (data) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };
      let url = "/certificates";
      if (data?.queryParams && !_.isEmpty(data?.queryParams)) {
        url = `${url}?${
          data?.queryParams instanceof Object
            ? qs.stringify(data?.queryParams)
            : data?.queryParams
        }`;
      }
      const response = await API.get(url, config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to load feedbacks");
    }
  }
);

export const postCertificates = createAsyncThunk(
  "postCertificates",
  async (data) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };
      const response = await API.post("/certificates", data.postdata, config);
      showSuccess("Certification Successfully Submit");
      return response.data;
    } catch (error) {
      throw new Error("Failed to load feedbacks");
    }
  }
);

const certificateSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCertificates.pending, (state) => {
        state.fetchLoading = true;
        state.message = "";
      })
      .addCase(fetchCertificates.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = null;
        state.data = action.payload;
      })
      .addCase(fetchCertificates.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.data = null;
        state.message = null;
        state.message = "";
      });
  },
});

export default certificateSlice.reducer;
