// import React from 'react'
// import iconFilePdf from "../../../assets/media/icon-file-pdf.svg";
// import iconFileXls from "../../../assets/media/icon-file-xls.svg";
// import iconFileImage from "../../../assets/media/icon-file-image.svg";
// import iconFileDoc from "../../../assets/media/icon-file-doc.svg";
// import iconFileDelete from "../../../assets/media/icon-file-delete.svg";
// import iconFileDownload from "../../../assets/media/icon-file-download.svg";
// import iconSearch from "../../../assets/media/icon-search.svg";
// import iconCaretLeft from "../../../assets/media/icon-caret-left.svg";
// import iconCaretRight from "../../../assets/media/icon-caret-right.svg";
// import iconCaretDown from "../../../assets/media/icon-caret-down.svg";

// import { Link } from 'react-router-dom';
// import Navbar from '../../navbar';
// import Header from '../../header';

// const PendingApp = () => {
//     return (
//         <div>
//             <Navbar />
//             <Header />
//             <div className='wrapper'>
//                 <div className='boxAppBoard boxWhite'>
//                     <div className='boardHeader'>
//                         <div className='boardHeadLeft'>
//                             <h2>Pending Applications</h2>
//                         </div>
//                         <div className='boardHeadRight'>
//                             <div className='searchForm'>
//                                 <input type='text' placeholder='input search text' />
//                                 <button><img src={iconSearch} alt='' /></button>
//                             </div>
//                             <div className='datePicker'>
//                             </div>
//                         </div>
//                     </div>
//                     <div className='boardContent'>
//                         <div className='boardThead'>
//                             <div className='boardCol w-85'>
//                                 S.NO.
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Application No.
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 Application Name
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Submitted Date
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Attachments
//                             </div>
//                             <div className='boardCol w-110'>

//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 01
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4936209
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Monthly Gaming Tax Return</strong>
//                                     <span>Gaming House Operator</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Nov 28, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 02
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4939907
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Gaming Licence Limited Renewal</strong>
//                                     <span>GBB 1-A-3</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 May 20, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 03
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4931109
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Monthly Gaming Tax Return</strong>
//                                     <span>Casino</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Nov 16, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 04
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4930098
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Proxy Gaming Licence</strong>
//                                     <span>GBB 1-B-1</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 May 9, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 05
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4934653
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Mobile Gaming Licence</strong>
//                                     <span>GBB 1-B-1</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Dec 2, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 06
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4930987
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Restricted Interactive Gaming Licence</strong>
//                                     <span>GBB 1-B-1</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Oct 30, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 07
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4936209
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Monthly Gaming Tax Return</strong>
//                                     <span>Gaming House Operator</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Nov 28, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 08
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4939907
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Gaming Licence Limited Renewal</strong>
//                                     <span>GBB 1-A-3</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 May 20, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 09
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4931109
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Monthly Gaming Tax Return</strong>
//                                     <span>Casino</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Nov 16, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 10
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4930098
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Proxy Gaming Licence</strong>
//                                     <span>GBB 1-B-1</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 May 9, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileXls} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='boardTrow'>
//                             <div className='boardCol w-85'>
//                                 11
//                             </div>
//                             <div className='boardCol w-165'>
//                                 GB-4934653
//                             </div>
//                             <div className='boardCol w-adj3'>
//                                 <div className='boardTitle'>
//                                     <strong>Mobile Gaming Licence</strong>
//                                     <span>GBB 1-B-1</span>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-165'>
//                                 Dec 2, 2022
//                             </div>
//                             <div className='boardCol w-165'>
//                                 <div className='boardDownload'>
//                                     <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileImage} alt='' /></Link>
//                                 </div>
//                             </div>
//                             <div className='boardCol w-110'>
//                                 <div className='boardActions'>
//                                     <Link to={'/'}><img src={iconFileDelete} alt='' /></Link>
//                                     <Link to={'/'}><img src={iconFileDownload} alt='' /></Link>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className='boardFooter'>
//                         <div className='pagination'>
//                             <Link className='disabled' to={'/'}><img src={iconCaretLeft} alt='' /></Link>
//                             <Link to={'/'}>1</Link>
//                             <Link to={'/'}>4</Link>
//                             <Link to={'/'}>5</Link>
//                             <Link className='active' to={'/'}>6</Link>
//                             <Link to={'/'}>7</Link>
//                             <Link to={'/'}>8</Link>
//                             <Link to={'/'}>50</Link>
//                             <Link to={'/'}><img src={iconCaretRight} alt='' /></Link>
//                             <Link className='wAuto' to={'/'}>10 / page <img className='ml-6' src={iconCaretDown} alt='' /></Link>
//                             <span>Goto</span>
//                             <input type='text' />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }
// export default PendingApp

import React, { useEffect, useState } from "react";
import iconFilePdf from "../../../assets/media/icon-file-pdf.svg";
import iconFileXls from "../../../assets/media/icon-file-xls.svg";
import iconFileImage from "../../../assets/media/icon-file-image.svg";
import iconFileDoc from "../../../assets/media/icon-file-doc.svg";
import iconFileDownload from "../../../assets/media/icon-file-download.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import iconCaretLeft from "../../../assets/media/icon-caret-left.svg";
import iconCaretRight from "../../../assets/media/icon-caret-right.svg";
import iconCaretDown from "../../../assets/media/icon-caret-down.svg";
import iconFileDelete from "../../../assets/media/icon-file-delete.svg";
import iconMenuDots from "../../../assets/media/icon-menu-dots.svg";
import { Link } from "react-router-dom";
import Navbar from "../../navbar";
import Header from "../../header";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../dashboard/aapDashboard";
import {
  fetchApplications,
  updateApplication,
} from "../../../store/reducers/applicationSlice";
import Pagination from "../dashboard/appPagination";
import { handleFilesDownload } from "../../../store/reducers/fileSlice";
import DatePicker from "react-datepicker";

const PendingApp = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const authToken = user.access_token;

  const applications = useSelector((state) => state.applications);

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const getallUserdata = useSelector((state) => state.userdata.getalluserdata);
  const [filteredApplication, setFilteredApplication] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  console.log("hello");
  const handleChangeStatus = async (item, state) => {
    await dispatch(
      updateApplication({
        id: item?.id,
        state: state,

        accessToken: authToken,
      })
    ).then((e) => {
      dispatch(fetchApplications({ accessToken: authToken }));
    });
  };

  useEffect(() => {
    let queryParams = {};
    if (startDate && endDate) {
      queryParams.start_date = startDate.toISOString();
      queryParams.end_date = endDate.toISOString();
    }
    dispatch(
      fetchApplications({ accessToken: authToken, queryParams: queryParams })
    );
  }, [authToken, dispatch, dateRange]);

  useEffect(() => {
    if (applications.data.length > 0 && getallUserdata.length > 0) {
      const mergedData = applications.data.map((appItem) => {
        const userItem = getallUserdata.find(
          (userData) => userData.id === appItem.user_id
        );
        if (userItem) {
          return {
            ...appItem,
            applicationId: appItem.id,
            ...userItem,
            userId: userItem.id,
          };
        }
        return { ...appItem, applicationId: appItem.id };
      });

      const filteredData = mergedData.filter(
        (item) =>
          (item.company_id === user.user.company_id || user.user.is_admin) &&
          item.state === "pending_from_dw" &&
          Object.values(item).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchQuery.toLowerCase())
          )
      );

      setFilteredApplication(filteredData);
      console.log(filteredData, "Filtered Data");
    }
  }, [searchQuery, applications.data, getallUserdata, user.user.company_id]);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getTotalPages = () => {
    if (Number.isInteger(filteredApplication.length / 10))
      return filteredApplication.length / 10;
    return Math.floor(filteredApplication.length / 10) + 1;
  };
  const handleDownload = (item) => {
    console.log(item);
    const applicationId = item.applicationId;
    const userId = item.userId;
    handleFilesDownload({ userId, applicationId, authToken });
  };

  return (
    <div>
      <Navbar />
      <Header />
      <div className="wrapper">
        <div className="boxAppBoard boxWhite">
          <div
            style={{ flexWrap: "wrap", gap: "10px" }}
            className="boardHeader"
          >
            <div className="boardHeadLeft">
              <h2>Pending From DW Applications</h2>
            </div>
            <div
              style={{
                gap: "10px",
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
              className="boardHeadRight"
            >
              <div className="searchForm">
                <input
                  type="text"
                  placeholder="input search text"
                  onChange={handleSearchInputChange}
                  value={searchQuery}
                />
                <button>
                  <img src={iconSearch} alt="" />
                </button>
              </div>
              <div className="datePicker">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Date Range"
                  className="date-range-input"
                  dateFormat="yyyy/MM/dd"
                />
              </div>
            </div>
          </div>
          <div className="boardContent">
            <div className="boardThead">
              <div className="boardCol w-85">S.NO.</div>
              <div className="boardCol w-165">Application No.</div>
              <div className="boardCol w-adj3">Application Name</div>
              <div className="boardCol w-165">Submitted Date</div>
              <div className="boardCol w-165"></div>
              <div className="boardCol w-110">Actions</div>
            </div>
            {filteredApplication
              .sort((a, b) => b.applicationId - a.applicationId)
              .map((item, index) => (
                <div
                  className={`boardTrow ${
                    (currentPage === 1
                      ? currentPage
                      : (currentPage - 1) * 10 + 1) <=
                      index + 1 &&
                    index < (currentPage === 1 ? 10 : currentPage * 10)
                      ? ""
                      : "d-none"
                  } `}
                  key={item.id}
                >
                  <div className="boardCol w-85">{index + 1}</div>
                  <div className="boardCol w-165">
                    {`GB-${item.applicationId}`}
                  </div>
                  <div className="boardCol w-adj3">
                    <div className="boardTitle">
                      <strong>{item.name}</strong>
                      <span>{item.category}</span>
                    </div>
                  </div>
                  <div className="boardCol w-165">
                    {item?.updated_at ? item.updated_at.slice(0, 10) : ""}
                  </div>
                  <div className="boardCol w-165">
                    <div className="boardDownload"></div>
                  </div>
                  <div className="boardCol w-110">
                    <div className="boardActions">
                      <div className="dropdown d-inline-flex">
                        <button
                          className="application-dropdown dropdown-toggle border-0 bg-transparent"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={iconMenuDots} alt="Menu Dots" />
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={() => handleDownload(item)}
                            >
                              Download
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => {
                                handleChangeStatus(item, 2); // 2 is pending
                              }}
                              className="dropdown-item"
                            >
                              Pending
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => {
                                handleChangeStatus(item, 3); // 3 is pending from dw
                              }}
                              className="dropdown-item"
                            >
                              Pending From Dw
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => {
                                handleChangeStatus(item, 4); // 4 is approved
                              }}
                              className="dropdown-item"
                            >
                              Approved
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => {
                                handleChangeStatus(item, 5); // 5 is reject
                              }}
                              className="dropdown-item"
                            >
                              Reject
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => {
                                handleChangeStatus(item, 7); // 7 is renewal
                              }}
                              className="dropdown-item"
                            >
                              Renewal
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() => {
                                handleChangeStatus(item, 8); // 8 is drafts
                              }}
                              className="dropdown-item"
                            >
                              Drafts
                            </Link>
                          </li>

                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Remove
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={getTotalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default PendingApp;
