import React, { useEffect, useState } from "react";
import iconFileDownload from "../../../assets/media/icon-file-download.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import iconPaper from "../../../assets/media/icon-paper.svg";

import { Link } from "react-router-dom";
import Navbar from "../../navbar";
import Header from "../../header";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApplications,
  fetchV2Applications,
} from "../../../store/reducers/applicationSlice";
import Pagination from "../dashboard/appPagination";
import { formatDate } from "../dashboard/aapDashboard";
import { handleFilesDownload } from "../../../store/reducers/fileSlice";
import DatePicker from "react-datepicker";

const AppRenewals = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const authToken = user.access_token;

  const applications = useSelector((state) => state.applications);

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const getallUserdata = useSelector((state) => state.userdata.getalluserdata);
  const [filteredApplication, setFilteredApplication] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  useEffect(() => {
    const fetchData = async () => {
      // Define query parameters
      const queryParams = {
        accessToken: authToken,
        state: "approved",
        dueInDays: 15,
        sortOrder: "DESC",
        sortField: "id",
      };
      if (startDate && endDate) {
        queryParams.start_date = startDate.toISOString();
        queryParams.end_date = endDate.toISOString();
      }

      // Fetch applications with query parameters
      const response = await fetchV2Applications(queryParams);
      setFilteredApplication(response?.applications || []);
    };

    fetchData();
  }, [authToken, dateRange]);

  useEffect(() => {
    if (applications.data.length > 0 && getallUserdata.length > 0) {
      console.log(applications);
      const mergedData = applications.data.map((appItem) => {
        const userItem = getallUserdata.find(
          (userData) => userData.id === appItem.user_id
        );
        if (userItem) {
          return {
            ...appItem,
            applicationId: appItem.id,
            ...userItem,
            userId: userItem.id,
          };
        }
        return { ...appItem, applicationId: appItem.id };
      });

      const filteredData = mergedData.filter(
        (item) =>
          (item.company_id === user.user.company_id || user.user.is_admin) &&
          item.state === "approved" &&
          Object.values(item).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchQuery.toLowerCase())
          )
      );

      setFilteredApplication(filteredData);
      console.log(filteredData, "Filtered Data");
    }
  }, [searchQuery, applications.data, getallUserdata, user.user.company_id]);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getTotalPages = () => {
    if (Number.isInteger(filteredApplication.length / 10))
      return filteredApplication.length / 10;
    return Math.floor(filteredApplication.length / 10) + 1;
  };

  const handleDownload = (item) => {
    const applicationId = item.id;
    const userId = item.userId;
    handleFilesDownload({ userId, applicationId, authToken });
  };

  return (
    <div>
      <Navbar />
      <Header />
      <div className="wrapper">
        <div className="boxAppBoard boxWhite">
          <div
            style={{ flexWrap: "wrap", gap: "10px" }}
            className="boardHeader"
          >
            <div className="boardHeadLeft">
              <h2>Renewals</h2>
            </div>
            <div
              style={{
                gap: "10px",
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
              className="boardHeadRight"
            >
              <div className="searchForm">
                <input
                  type="text"
                  placeholder="input search text"
                  onChange={handleSearchInputChange}
                  value={searchQuery}
                />
                <button>
                  <img src={iconSearch} alt="" />
                </button>
              </div>
              <div className="datePicker">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Date Range"
                  className="date-range-input"
                  dateFormat="yyyy/MM/dd"
                />
              </div>
            </div>
          </div>
          <div className="boardContent">
            <div className="boardThead">
              <div className="boardCol w-85">S.NO.</div>
              <div className="boardCol w-165">Application No.</div>
              <div className="boardCol w-adj4">Application Name</div>
              <div className="boardCol w-165">Due Date</div>
              <div className="boardCol w-165">Last Registered</div>
              <div className="boardCol w-165"></div>
              <div className="boardCol w-110"></div>
            </div>
            {filteredApplication
              .sort((a, b) => b.id - a.id)
              .map((item, index) => (
                <div
                  className={`boardTrow ${
                    (currentPage === 1
                      ? currentPage
                      : (currentPage - 1) * 10 + 1) <=
                      index + 1 &&
                    index < (currentPage === 1 ? 10 : currentPage * 10)
                      ? ""
                      : "d-none"
                  } `}
                  key={item.id}
                >
                  <div className="boardCol w-85">{index + 1}</div>
                  <div className="boardCol w-165">{`GB-${item.id}`}</div>
                  <div className="boardCol w-adj4">
                    <div className="boardTitle">
                      <strong>{item.name}</strong>
                      <span>{item.categoryName}</span>
                    </div>
                  </div>
                  <div className="boardCol w-165">
                    {formatDate(item.due_date)}
                  </div>
                  <div className="boardCol w-165">
                    {formatDate(item.updated_at)}
                  </div>

                  <div className="boardCol w-110">
                    <div className="boardActions">
                      <Link onClick={() => handleDownload(item)}>
                        <img src={iconFileDownload} alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={getTotalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default AppRenewals;
