import React, { useEffect, useState } from "react";
import iconFilePdf from "../../../assets/media/icon-file-pdf.svg";
import iconFileXls from "../../../assets/media/icon-file-xls.svg";
import iconFileImage from "../../../assets/media/icon-file-image.svg";
import iconFileDoc from "../../../assets/media/icon-file-doc.svg";
import iconFileEdit from "../../../assets/media/icon-file-edit.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import iconCaretLeft from "../../../assets/media/icon-caret-left.svg";
import iconCaretRight from "../../../assets/media/icon-caret-right.svg";
import iconCaretDown from "../../../assets/media/icon-caret-down.svg";

import { Link } from "react-router-dom";
import Navbar from "../../navbar";
import Header from "../../header";
import { useDispatch, useSelector } from "react-redux";
import { fetchApplications } from "../../../store/reducers/applicationSlice";
import Pagination from "../dashboard/appPagination";
import { formatDate } from "../dashboard/aapDashboard";
import DatePicker from "react-datepicker";

const AppDraft = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const authToken = user.access_token;

  const applications = useSelector((state) => state.applications);

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const getallUserdata = useSelector((state) => state.userdata.getalluserdata);
  const [filteredApplication, setFilteredApplication] = useState([]);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    let queryParams = {};
    if (startDate && endDate) {
      queryParams.start_date = startDate.toISOString();
      queryParams.end_date = endDate.toISOString();
    }
    dispatch(
      fetchApplications({ accessToken: authToken, queryParams: queryParams })
    );
  }, [authToken, dispatch, dateRange]);

  useEffect(() => {
    if (applications.data.length > 0 && getallUserdata.length > 0) {
      const mergedData = applications.data.map((appItem) => {
        const userItem = getallUserdata.find(
          (userData) => userData.id === appItem.user_id
        );
        if (userItem) {
          return {
            ...appItem,
            applicationId: appItem.id,
            ...userItem,
            userId: userItem.id,
          };
        }
        return { ...appItem, applicationId: appItem.id };
      });

      const filteredData = mergedData.filter(
        (item) =>
          item.company_id === user.user.company_id &&
          item.state === "drafts" &&
          Object.values(item).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchQuery.toLowerCase())
          )
      );

      setFilteredApplication(filteredData);
      console.log(filteredData, "Filtered Data");
    }
  }, [searchQuery, applications.data, getallUserdata, user.user.company_id]);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getTotalPages = () => {
    if (Number.isInteger(filteredApplication.length / 10))
      return filteredApplication.length / 10;
    return Math.floor(filteredApplication.length / 10) + 1;
  };

  return (
    <div>
      <Navbar />
      <Header />
      <div className="wrapper">
        <div className="boxAppBoard boxWhite">
          <div
            className="boardHeader"
            style={{ flexWrap: "wrap", gap: "10px" }}
          >
            <div className="boardHeadLeft">
              <h2>Draft</h2>
            </div>
            <div
              className="boardHeadRight"
              style={{
                gap: "10px",
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
            >
              <div className="searchForm">
                <input
                  type="text"
                  placeholder="input search text"
                  onChange={handleSearchInputChange}
                  value={searchQuery}
                />
                <button>
                  <img src={iconSearch} alt="" />
                </button>
              </div>
              <div className="datePicker">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Date Range"
                  className="date-range-input"
                  dateFormat="yyyy/MM/dd"
                />
              </div>
            </div>
          </div>
          <div className="boardContent">
            <div className="boardThead">
              <div className="boardCol w-85">S.NO.</div>
              <div className="boardCol w-165">Application No.</div>
              <div className="boardCol w-adj2">Application Name</div>
              <div className="boardCol w-165">Last Fill</div>
              <div className="boardCol w-355">Completed</div>
              <div className="boardCol w-165">{/* Attachments */}</div>
              <div className="boardCol w-110"></div>
            </div>
            {filteredApplication
              .sort((a, b) => b.applicationId - a.applicationId)
              .map((item, index) => (
                <div
                  className={`boardTrow ${
                    (currentPage === 1
                      ? currentPage
                      : (currentPage - 1) * 10 + 1) <=
                      index + 1 &&
                    index < (currentPage === 1 ? 10 : currentPage * 10)
                      ? ""
                      : "d-none"
                  } `}
                  key={item.id}
                >
                  <div className="boardCol w-85">{index + 1}</div>
                  <div className="boardCol w-165">
                    {`GB-${item.applicationId}`}
                  </div>
                  <div className="boardCol w-adj2">
                    <div className="boardTitle">
                      <strong>{item.name}</strong>
                      <span>{item.category}</span>
                    </div>
                  </div>
                  <div className="boardCol w-165">
                    {formatDate(item.created_at)}
                  </div>
                  <div className="boardCol w-355">
                    <div className="progressWrap">
                      <div
                        className="progress"
                        role="progressbar"
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div
                          className="progress-bar progWorking"
                          style={{ width: "50%" }}
                        ></div>
                      </div>
                      <div className="progressStatus">50%</div>
                    </div>
                  </div>
                  <div className="boardCol w-165">
                    <div className="boardDownload">
                      {/* <Link to={'/'}><img src={iconFilePdf} alt='' /></Link>
                                            <Link to={'/'}><img src={iconFileDoc} alt='' /></Link>
                                            <Link to={'/'}><img src={iconFileImage} alt='' /></Link> */}
                    </div>
                  </div>
                  <div className="boardCol w-110">
                    <div className="boardActions">
                      <Link to={"/"}>
                        <img src={iconFileEdit} alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={getTotalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default AppDraft;
