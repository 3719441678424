import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import iconFileDelete from "../../../assets/media/icon-file-delete.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import iconRestore from "../../../assets/svgicons/restore.svg";
import Navbar from "../../navbar";
import Header from "../../header";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../dashboard/appPagination";
import {
  createCompany,
  deleteCompany,
  fetchCompanies,
  updateCompany,
} from "../../../store/reducers/companiesSlice.js";
import iconFileEdit from "../../../assets/media/icon-file-edit.svg";
import { addEmployee } from "../../../store/reducers/employeeSlice.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import ConfirmationModal from "../../common/confirmationModal.jsx";

const Companies = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const user = useSelector((state) => state.auth.user);
  const createLoading = useSelector((state) => state.employee.createLoading);
  const fetchLoading = useSelector((state) => state.employee.fetchLoading);
  const [selected_Company, setSelected_Company] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [isRestore, setIsRestore] = useState(false);

  const authToken = user.access_token;

  const initialValues = {
    company_id: "",
    company_name: "",
    no_of_employee: "",
    company_email: "",
    registration_date: "",
    phone_no: "",
  };

  const validationSchema = Yup.object({
    company_id: Yup.string().required("Company ID is required"),
    company_name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .required("Full Name is required"),
    company_email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    no_of_employee: Yup.string().required("Number of employees is required"),
    registration_date: Yup.string().required("Registration date is required"),
    phone_no: Yup.string().required("Phone number is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const {
        company_email,
        company_id,
        company_name,
        no_of_employee,
        registration_date,
        phone_no,
      } = values;
      console.log(registration_date, "kkkkkkkkkkkkkkkk");
      const formattedDate = moment(registration_date)
        .add(1, "days")
        .format("YYYY/MM/DD");
      if (selected_Company) {
        dispatch(
          updateCompany({
            companyId: selected_Company.id,
            companyData: {
              company_id,
              company_name,
              company_email,
              no_of_employee,
              registration_date: formattedDate,
              phone_no,
            },
            accessToken: authToken,
          })
        );
        setSelected_Company(null);
      } else {
        dispatch(
          createCompany({
            companyData: {
              company_id,
              company_name,
              company_email,
              no_of_employee,
              registration_date: formattedDate,
              phone_no,
            },
            accessToken: authToken,
          })
        ).then((createCompany) => {
          if (createCompany.payload && createCompany.payload.id) {
            dispatch(
              addEmployee({
                userData: {
                  full_name: company_name,
                  national_id_number: "",
                  email: company_email,
                  phone: phone_no,
                  roles: [
                    "create_discrepencies",
                    "issue_certificates",
                    "create_forms",
                    "add_delete_users",
                  ],
                  is_admin: false,
                  company_id: createCompany.payload.id,
                  user_role: "company_admin",
                },
                accessToken: authToken,
              })
            );
          }
        });
      }
    },
  });

  const { values, handleChange, handleSubmit, handleBlur, errors } = formik;

  const company = useSelector((state) => state.companies.companies);
  const fetchcompanydata = () => {
    let queryParams = {};
    if (startDate && endDate) {
      queryParams.start_date = startDate.toISOString();
      queryParams.end_date = endDate.toISOString();
    }
    dispatch(fetchCompanies({ authToken, queryParams }));
  };

  const deletecompanydata = (id) => {
    const config = {
      accessToken: authToken,
      company_id: id,
    };
    setSelected_Company(null);
    dispatch(deleteCompany({ configdata: config }));
  };

  useEffect(() => {
    fetchcompanydata();
  }, [authToken, dispatch, dateRange]);

  const getTotalPages = () => {
    if (Number.isInteger(company.length / 10)) return company.length / 10;
    return Math.floor(company.length / 10) + 1;
  };

  const selectedCompany = (data) => {
    setSelected_Company({
      id: data.id,
      company_id: data.company_id,
      company_name: data.company_name,
      company_email: data.company_email,
      no_of_employee: data.no_of_employee,
      registration_date: data.registration_date,
      phone_no: data.phone_no,
    });
    formik.setValues(data);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    setFilteredCompanies(
      company
        .filter((item) =>
          Object.values(item).some((value) =>
            item.toString().toLowerCase().includes(searchQuery.toLowerCase())
          )
        )
        .sort((a, b) => b.id - a.id)
    );
  }, [company, searchQuery]);

  return (
    <div>
      <Navbar />
      <Header />
      <div className="wrapper">
        <div className="boxAppBoard boxWhite">
          <div className="boardHeader noMg">
            <div className="boardHeadLeft">
              <h2>Registered Companies</h2>
            </div>
          </div>
        </div>
        <div className="empAdd">
          <h3 className="noMg">Add Company </h3>
          <form className="addEmployee-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="fieldWrap">
                  <label>Company id</label>
                  <input
                    name="company_id"
                    type="text"
                    placeholder="GB-4936209"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company_id}
                    error={errors.company_id}
                  />
                  {errors.company_id && (
                    <div className="error-message">{errors.company_id}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="fieldWrap">
                  <label>Company Name</label>
                  <input
                    name="company_name"
                    type="text"
                    placeholder="Abc Company"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company_name}
                    error={errors.company_name}
                  />
                  {errors.company_name && (
                    <div className="error-message">{errors.company_name}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="fieldWrap">
                  <label>Company Email</label>
                  <input
                    name="company_email"
                    type="text"
                    placeholder="Abc@gmail.com"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company_email}
                    error={errors.company_email}
                  />
                  {errors.company_email && (
                    <div className="error-message">{errors.company_email}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="fieldWrap">
                  <label>No of employee</label>
                  <input
                    name="no_of_employee"
                    type="text"
                    placeholder="1,2,3....."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.no_of_employee}
                    error={errors.no_of_employee}
                  />
                  {errors.no_of_employee && (
                    <div className="error-message">{errors.no_of_employee}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="fieldWrap">
                  <label>Registration Date</label>
                  <DatePicker
                    name="registration_date"
                    selected={values.registration_date}
                    onChange={(date) =>
                      formik.setFieldValue("registration_date", date)
                    }
                    onBlur={handleBlur}
                    dateFormat="yyyy/MM/dd"
                    placeholderText="Select a date"
                    className="form-control datepicker-container"
                  />
                  {errors.registration_date && (
                    <div className="error-message">
                      {errors.registration_date}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <div className="fieldWrap">
                  <label>Phone No.</label>
                  <PhoneInput
                    placeholder={"(702) 555-0122"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    country={"us"}
                    autoFormat={true}
                    inputProps={{
                      name: "phone_no",
                      value: values.phone,
                      onChange: handleChange,
                      onBlur: handleBlur,
                      errors: errors.phone_no,
                    }}
                    className={errors.phone_no ? "error-input" : ""}
                  />
                  {/* <input
                    name="phone_no"
                    type="text"
                    placeholder="(702) 555-0122"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone_no}
                    error={errors.phone_no}
                  /> */}
                  {errors.phone_no && (
                    <div className="error-message">{errors.phone_no}</div>
                  )}
                </div>
              </div>

              <div className="d-flex align-items-end justify-content-between mt-5">
                <div className="boardHeadRight">
                  <div className="boardFilter"></div>
                </div>

                <div className="">
                  <div className="fieldWrap noLabel">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={createLoading}
                    >
                      {selected_Company ? (
                        <>
                          {createLoading ? (
                            <>
                              <div
                                className="text-xs spinner-border text-light"
                                style={{ width: "1rem", height: "1rem" }}
                                role="status"
                              ></div>
                              <span className="sr-only ml-5"> Loading...</span>
                            </>
                          ) : (
                            "Update Company"
                          )}
                        </>
                      ) : (
                        <>
                          {createLoading ? (
                            <>
                              <div
                                className="text-xs spinner-border text-light"
                                style={{ width: "1rem", height: "1rem" }}
                                role="status"
                              ></div>
                              <span className="sr-only ml-5"> Loading...</span>
                            </>
                          ) : (
                            "Add Company"
                          )}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="boxAppBoard boxWhite mt-5">
          <div
            style={{ flexWrap: "wrap", gap: "10px" }}
            className="boardHeader"
          >
            <div className="boardHeadLeft">
              <h2>All Companies</h2>
            </div>
            <div
              style={{
                gap: "10px",
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
              className="boardHeadRight"
            >
              <div className="searchForm">
                <input
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  type="text"
                  placeholder="input search text"
                />
                <button>
                  <img src={iconSearch} alt="" />
                </button>
              </div>
              <div className="datePicker">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Date Range"
                  className="date-range-input"
                  dateFormat="yyyy/MM/dd"
                />
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead className="boardTheadcompany">
                <tr>
                  <th
                    style={{ whiteSpace: "nowrap" }}
                    className="boardCol w-adj2"
                  >
                    S.NO.
                  </th>
                  <th
                    style={{ whiteSpace: "nowrap" }}
                    className="boardCol w-adj2"
                  >
                    Company Id
                  </th>
                  <th
                    style={{ whiteSpace: "nowrap" }}
                    className="boardCol w-adj2"
                  >
                    Company Name
                  </th>
                  <th
                    style={{ whiteSpace: "nowrap" }}
                    className="boardCol w-adj2"
                  >
                    Company Email
                  </th>
                  <th
                    style={{ whiteSpace: "nowrap" }}
                    className="boardCol w-adj2"
                  >
                    No Of Employee
                  </th>
                  <th
                    style={{ whiteSpace: "nowrap" }}
                    className="boardCol w-adj2"
                  >
                    Registration Date
                  </th>
                  <th
                    style={{ whiteSpace: "nowrap" }}
                    className="boardCol w-adj2"
                  >
                    Phone No.
                  </th>
                  <th
                    style={{ whiteSpace: "nowrap" }}
                    className="boardCol w-adj2"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {fetchLoading ? (
                  <tr>
                    <td className="text-center" colSpan="8">
                      <div className="d-flex justify-content-center my-4">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : company ? (
                  filteredCompanies.map((item, index) => (
                    <tr
                      key={index}
                      className={`boardTrowcompany ${
                        (currentPage === 1
                          ? currentPage
                          : (currentPage - 1) * 10 + 1) <=
                          index + 1 &&
                        index < (currentPage === 1 ? 10 : currentPage * 10)
                          ? ""
                          : "d-none"
                      } `}
                    >
                      <td
                        style={{ whiteSpace: "nowrap" }}
                        className="boardCol w-adj2"
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{ whiteSpace: "nowrap" }}
                        className="boardCol w-adj2"
                      >
                        {item.company_id}
                      </td>
                      <td
                        style={{ whiteSpace: "nowrap" }}
                        className="boardCol w-adj2"
                      >
                        {item.company_name}
                      </td>
                      <td
                        style={{ whiteSpace: "nowrap" }}
                        className="boardCol w-adj2"
                      >
                        <a
                          style={{ whiteSpace: "nowrap" }}
                          href={`mailto:${item.company_email}`}
                        >
                          {item.company_email}
                        </a>
                      </td>
                      <td className="boardCol w-adj2">
                        <a style={{ whiteSpace: "nowrap" }}>
                          {item.no_of_employee}
                        </a>
                      </td>
                      <td
                        style={{ whiteSpace: "nowrap" }}
                        className="boardCol w-adj2"
                      >
                        {item.registration_date?.slice(0, 10)}
                      </td>
                      <td className="boardCol w-adj2">
                        <a
                          style={{ whiteSpace: "nowrap" }}
                          href={`tel:${item.phone_no}`}
                        >
                          {item.phone_no}
                        </a>
                      </td>
                      <td
                        className="boardCol w-adj2 "
                        style={{ marginRight: 10 }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginLeft: 20,
                          }}
                        >
                          <Link>
                            <img
                              onClick={() => {
                                setShowDeleteModal(true);
                                setDeleteUserId(item.id);
                                setIsRestore(item.is_deleted);
                              }}
                              src={
                                item.is_deleted ? iconRestore : iconFileDelete
                              }
                              alt=""
                              style={{
                                cursor: "pointer",
                                minWidth: "24px",
                                minHeight: "24px",
                              }}
                            />
                          </Link>
                          <Link onClick={() => selectedCompany(item)}>
                            <img
                              src={iconFileEdit}
                              alt=""
                              style={{
                                cursor: "pointer",
                                minWidth: "24px",
                                minHeight: "24px",
                              }}
                            />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      <h2 className="text-danger my-4 py-4">
                        Companies not Found
                      </h2>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={getTotalPages}
          />
        </div>
      </div>
      <ConfirmationModal
        show={showDeleteModal && deleteUserId}
        title={`${isRestore ? "Restore" : "Delete"} Company`}
        description={`Are you sure you want to ${
          isRestore ? "restore" : "delete"
        } this company?`}
        handleClose={() => {
          setShowDeleteModal(false);
          setDeleteUserId(null);
          setIsRestore(false);
        }}
        onConfirm={() => {
          deletecompanydata(deleteUserId);
          setShowDeleteModal(false);
          setDeleteUserId(null);
          setIsRestore(false);
        }}
      />
    </div>
  );
};
export default Companies;
