import React, { useEffect, useState } from "react";
import iconFilePdf from "../../../assets/media/icon-file-pdf.svg";
import iconFileXls from "../../../assets/media/icon-file-xls.svg";
import iconFileImage from "../../../assets/media/icon-file-image.svg";
import iconFileDoc from "../../../assets/media/icon-file-doc.svg";
import iconFileDownload from "../../../assets/media/icon-file-download.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import iconCaretLeft from "../../../assets/media/icon-caret-left.svg";
import iconCaretRight from "../../../assets/media/icon-caret-right.svg";
import iconCaretDown from "../../../assets/media/icon-caret-down.svg";
import iconMenuDots from "../../../assets/media/icon-menu-dots.svg";

import { Link } from "react-router-dom";
import Navbar from "../../navbar";
import Header from "../../header";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../dashboard/aapDashboard";
import {
  fetchApplications,
  updateApplication,
  addCertificateAndRenewal,
} from "../../../store/reducers/applicationSlice";
import Pagination from "../dashboard/appPagination";
import Modal from "react-modal";
import {
  fetchCertificates,
  postCertificates,
} from "../../../store/reducers/certificateSlice";
import { createNotification } from "../../../store/reducers/notificationSlice";
import iconFilecertification from "../../../assets/media/icon-certification.svg";
import ReactModal from "react-modal";
import { showError } from "../../../helpers/toast";
import { handleFilesDownload } from "../../../store/reducers/fileSlice";
import CertificateUploadModel from "../applications/certificateUploadModel";
import DatePicker from "react-datepicker";
const ApprovedApp = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const authToken = user.access_token;
  const applications = useSelector((state) => state.applications);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const getallUserdata = useSelector((state) => state.userdata.getalluserdata);
  const [filteredApplication, setFilteredApplication] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedDuaration, setselectedDuaration] = useState("");
  const categories = useSelector((state) => state.categories.data);
  const [selectedItem, setSelectedItem] = useState([]);
  const certificates = useSelector((state) => state.certificates);
  const [showCertificateModal, setShowCertificateModal] = useState(false);
  const [seletctedApplicationId, setSelectedApplicationId] = useState(null);
  const [selectedCertificateFile, setSelectedCertificateFile] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  useEffect(() => {
    let queryParams = {};
    if (startDate && endDate) {
      queryParams.start_date = startDate.toISOString();
      queryParams.end_date = endDate.toISOString();
    }
    dispatch(
      fetchApplications({ accessToken: authToken, queryParams: queryParams })
    );
    dispatch(
      fetchCertificates({ accessToken: authToken, queryParams: queryParams })
    );
  }, [authToken, dispatch, dateRange]);
  const [issuedTo, setIssuedTo] = useState(0);

  useEffect(() => {
    if (applications.data.length > 0 && getallUserdata.length > 0) {
      const mergedData = applications.data.map((appItem) => {
        const userItem = getallUserdata.find(
          (userData) => userData.id === appItem.user_id
        );
        if (userItem) {
          return {
            ...appItem,
            applicationId: appItem.id,
            ...userItem,
            userId: userItem.id,
          };
        }
        return { ...appItem, applicationId: appItem.id };
      });

      const filteredData = mergedData.filter(
        (item) =>
          (item.company_id === user.user.company_id || user.user.is_admin) &&
          item.state === "approved" &&
          Object.values(item).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchQuery.toLowerCase())
          )
      );

      setFilteredApplication(filteredData);
      console.log(filteredData, "Filtered Data");
    }
  }, [searchQuery, applications.data, getallUserdata, user.user.company_id]);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getTotalPages = () => {
    if (Number.isInteger(filteredApplication.length / 10))
      return filteredApplication.length / 10;
    return Math.floor(filteredApplication.length / 10) + 1;
  };

  const handleDownload = (item) => {
    const applicationId = item.applicationId;
    const userId = item.userId;
    handleFilesDownload({ userId, applicationId, authToken });
  };

  // Function to toggle the modal
  const handleShow = (item) => {
    setIssuedTo(item.userId);
    setSelectedApplicationId(item.applicationId);
    setShowCertificateModal(true);
  };
  const handleClose = () => setShowCertificateModal(false);

  // Function to handle file upload (dummy example)
  const handleCertificateUpload = (startDate, endDate) => {
    if (selectedCertificateFile) {
      console.log(issuedTo);
      const renamedFile = new File(
        [selectedCertificateFile],
        `${Date.now()}-${selectedCertificateFile.name}`,
        { type: selectedCertificateFile.type }
      );
      addCertificateAndRenewal({
        issue_date: startDate,
        due_date: endDate,
        file: renamedFile,
        authToken: authToken,
        id: seletctedApplicationId,
        user_id: issuedTo,
      });
      handleClose(); // Close the modal after upload
    } else {
      addCertificateAndRenewal({
        issue_date: startDate,
        due_date: endDate,
        file: selectedCertificateFile,
        authToken: authToken,
        id: seletctedApplicationId,
        user_id: issuedTo,
      });
      handleClose(); // Close the modal after upload
    }
  };

  return (
    <div>
      <Navbar />
      <Header />
      <div className="wrapper">
        <div className="boxAppBoard boxWhite">
          <div
            className="boardHeader"
            style={{ flexWrap: "wrap", gap: "10px" }}
          >
            <div className="boardHeadLeft">
              <h2>Approved Applications</h2>
            </div>
            <div
              style={{
                gap: "10px",
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
              className="boardHeadRight"
            >
              <div className="searchForm">
                <input
                  type="text"
                  placeholder="input search text"
                  onChange={handleSearchInputChange}
                  value={searchQuery}
                />
                <button>
                  <img src={iconSearch} alt="" />
                </button>
              </div>
              <div className="datePicker">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Date Range"
                  className="date-range-input"
                  dateFormat="yyyy/MM/dd"
                />
              </div>
            </div>
          </div>
          <div className="boardContent">
            <div className="boardThead">
              <div className="boardCol w-85">S.NO.</div>
              <div className="boardCol w-165">Application No.</div>
              <div className="boardCol w-adj3">Application Name</div>
              <div className="boardCol w-165">Submitted Date</div>
              <div className="boardCol w-165">Certification</div>
              <div className="boardCol w-110">Actions</div>
            </div>
            {filteredApplication
              .sort((a, b) => b.applicationId - a.applicationId)
              .map((item, index) => (
                <div
                  className={`boardTrow ${
                    (currentPage === 1
                      ? currentPage
                      : (currentPage - 1) * 10 + 1) <=
                      index + 1 &&
                    index < (currentPage === 1 ? 10 : currentPage * 10)
                      ? ""
                      : "d-none"
                  } `}
                  key={item.id}
                >
                  <div className="boardCol w-85">{index + 1}</div>
                  <div className="boardCol w-165">
                    {`GB-${item.applicationId}`}
                  </div>
                  <div className="boardCol w-adj3">
                    <div className="boardTitle">
                      <strong>{item.name}</strong>
                      <span>{item.category}</span>
                    </div>
                  </div>
                  <div className="boardCol w-165">
                    {item?.updated_at ? item.updated_at.slice(0, 10) : ""}
                  </div>
                  <div className="boardCol w-165 ">
                    {certificates.data.filter(
                      (cert) => cert.application_id === item.applicationId
                    ).length === 0 && (
                      <div
                        className="boardDownload"
                        onClick={() => {
                          handleShow(item);
                        }}
                      >
                        <img
                          style={{ marginLeft: "30px" }}
                          src={iconFilecertification}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                  <div className="boardCol w-110">
                    <div className="boardActions">
                      <div className="dropdown d-inline-flex">
                        <button
                          className="application-dropdown dropdown-toggle border-0 bg-transparent"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={iconMenuDots} alt="Menu Dots" />
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li>
                            <Link
                              to={"/"}
                              className="dropdown-item"
                              onClick={() => handleDownload(item)}
                            >
                              Download
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={() => handleShow(item)}
                            >
                              Certificate & Renewal
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <CertificateUploadModel
                        handleFileUpload={handleCertificateUpload}
                        handleClose={handleClose}
                        showModal={showCertificateModal}
                        selectedCertificateFile={selectedCertificateFile}
                        setSelectedCertificateFile={setSelectedCertificateFile}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={getTotalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default ApprovedApp;
