import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../navbar";
import Header from "../../header";
import { useDispatch, useSelector } from "react-redux";
import { fetchCertificates } from "../../../store/reducers/certificateSlice";
import imageCertificate from "../../../assets/media/image-certificate.png";
import { getAllCertificates } from "../../../store/reducers/applicationSlice";
import iconFileDownload from "../../../assets/media/icon-file-download.svg";
import { downloadCertificate } from "../../../store/reducers/fileSlice";
const AppCertifications = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const authToken = user.access_token;
  const [certificates, setCertificates] = useState(null);
  const [thumbnails, setThumbnails] = useState(null);
  useEffect(() => {
    async function getCertificates() {
      let response = await getAllCertificates({ authToken: authToken });
      setCertificates(response?.applications);
      setThumbnails(response?.thumbnailsObj);
    }
    if (authToken) {
      getCertificates();
    }
  }, [authToken]);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const options = { year: "numeric", month: "long", day: "2-digit" };
    return date.toLocaleDateString(undefined, options);
  };

  function hasDatePassed(dateString) {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    return inputDate < currentDate;
  }

  const categorizeCertificatesWithExpiry = () => {
    let expiredCertificates = [],
      validCertificates = [];
    certificates?.data?.forEach((item) => {
      if (hasDatePassed(item.expired_at)) {
        expiredCertificates.push(item);
      } else {
        validCertificates.push(item);
      }
    });
    return {
      expiredCertificates,
      validCertificates,
    };
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  useEffect(() => {
    console.log(thumbnails);
  }, [thumbnails]);

  return (
    <div>
      <Navbar />
      <Header handleSearchInputChange={handleSearchInputChange} />
      <div className="wrapper">
        <div className="boxAppBoard boxWhite">
          <div className="boardHeader noMg">
            <div className="boardHeadLeft">
              <h2>Certifications</h2>
            </div>
            <div className="boardHeadRight">
              <div className="boardFilter">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="All"
                    checked
                  />
                  <label className="form-check-label">All</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="Near To Expire"
                  />
                  <label className="form-check-label">Near To Expire</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="Expired"
                  />
                  <label className="form-check-label">Expired</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divFiles">
          <div className="rowCertifications">
            {certificates?.length ? (
              certificates.map((item, index) => (
                <div className="colCertification" key={index}>
                  <img src={thumbnails[item.id]} alt="Thumbnail" />
                  <div className="contCertificate">
                    <h5 title={item.name}>
                      {item?.name.substring(0, 12) + "..."}
                    </h5>
                    <div className="issue">
                      Issued: <span>{formatDate(item?.issue_date)}</span>
                    </div>
                    <div className="issue">
                      Expire:{" "}
                      <span className="due">{formatDate(item?.due_date)}</span>
                    </div>
                    <button
                      onClick={() => downloadCertificate(item.id, authToken)}
                    >
                      Download
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No Certificate Found</p>
            )}
          </div>
        </div>
        <div className="divFiles">
          <div className="headFiles">
            <div className="leftFiles">
              <h3 className="noMg">Expired</h3>
            </div>
            <div className="rightFiles"></div>
          </div>
          <div className="rowCertifications">
            {categorizeCertificatesWithExpiry()?.expiredCertificates.length ? (
              categorizeCertificatesWithExpiry()?.expiredCertificates.map(
                (item, index) => (
                  <div className="colCertification" key={index}>
                    <img src={thumbnails[item.id]} alt="" />
                    <div className="contCertificate">
                      <h5>{item?.name}</h5>
                      <div className="issue">
                        Issued: <span>{formatDate(item?.issue_date)}</span>
                      </div>
                      <div className="expire">
                        Expire:{" "}
                        <span className="due">
                          {formatDate(item?.due_date)}
                        </span>
                      </div>
                      <button
                        onClick={() => downloadCertificate(item.id, authToken)}
                      >
                        Download
                      </button>
                    </div>
                  </div>
                )
              )
            ) : (
              <p>No Certificate Found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppCertifications;
